import { Token } from '@pancakeswap/sdk'
import { useMemo } from 'react'
import { useAllTokenBalances } from '../../state/wallet/hooks'

const PINNED_SORTING = [
  'PLS',
  '9INCH',
  'BBC',
  'DAI',
  'USDC',
  'USDT',
  'WETH',
  'SOLIDX',
  'NOPE',
  'AXIS',
  'FIRE',
  'TEVE',
  'BRO',
  'BEAR',
  'ATROPA',
  'HEX',
]

function useTokenComparator(inverted: boolean): (tokenA: Token, tokenB: Token) => number {
  const allBalances = useAllTokenBalances()

  return useMemo(() => {

    const getBalance = (token: Token) => {
      const balance = allBalances[token.address]?.toExact()
      return balance ? parseFloat(balance) : 0
    }

    const comparator = (tokenA: Token, tokenB: Token): number => {
      const balanceA = getBalance(tokenA)
      const balanceB = getBalance(tokenB)


      // Compare balances (highest to lowest)
      if (balanceA > balanceB) return -1
      if (balanceA < balanceB) return 1

      // If balances are equal, check for pinned tokens
      const pinnedOrderA = PINNED_SORTING.indexOf(tokenA.symbol?.toUpperCase())
      const pinnedOrderB = PINNED_SORTING.indexOf(tokenB.symbol?.toUpperCase())
      
      if (pinnedOrderA > -1 && pinnedOrderB > -1) return pinnedOrderA - pinnedOrderB
      if (pinnedOrderA > -1) return -1
      if (pinnedOrderB > -1) return 1

      // If neither is pinned, sort alphabetically
      return tokenA.symbol.toLowerCase().localeCompare(tokenB.symbol.toLowerCase())
    }

    return inverted ? (tokenA: Token, tokenB: Token) => comparator(tokenA, tokenB) * -1 : comparator
  }, [inverted, allBalances])
}

export default useTokenComparator
