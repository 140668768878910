import { Input } from '@pancakeswap/uikit'
import styled from 'styled-components'

const Box = styled.div`
  width: 100%;
  border-radius: 4px;
  position: relative;
`

const BottomLeft = styled.img`
  position: absolute;
  bottom: 0px;
  left: 0px;
`

const BottomRight = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0px;
`

const EInput = styled(Input)`
  background-color: ${({ theme }) => theme.colors.input};
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.text};
  display: block;
  font-size: 16px;
  outline: 0;
  padding: 0 16px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.textSubtle};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.textDisabled};
    cursor: not-allowed;
  }
`

const ESearchBox = (props: any) => {
  return (
    <Box
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <EInput placeholder="Search" {...props} />
    </Box>
  )
}

export default ESearchBox
